import { Backdrop, Box, Button, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import OnboardingPersonalInfo from "./steps/Onboarding.personal.info";
import OnboardingCropInfo from "./steps/Onboarding.crop.info";
import OnboardingConfirmation from "./steps/Onboarding.confirmation";
import OnboardingService from "../../services/Onboarding";
import { useNavigate } from "react-router-dom";
import useError from "../../hooks/error";
import OnboardingSeedingInfo from "./steps/Onboarding.seeding.info";
import Member from "../../models/Member";
import CropType from "../../models/CropType.enum";
import OnboardingPriceInfo from "./steps/Onboarding.price.info";
import blocks from "../../configs/block.config.json";
import PaymentStatus from "../../models/PaymentStatus.enum";

function OnboardingForm() {
  const { setError } = useError();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const { register, handleSubmit, control, formState, getFieldState, setValue, watch } = useForm<Member>({mode: 'onBlur'});
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();

  const lastStep = 4;

  const onSubmit: SubmitHandler<Member> = data => {
    data.payment.status = PaymentStatus.pending;
    setLoading(true);
    OnboardingService.save(data).then(() => {
      setStep(lastStep);
    }).catch(error => {
      console.log(error)
      setError(error.statusCode)
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleNext: SubmitHandler<Member> = data => {
    if (formState.errors.personal === undefined
      && formState.errors.crop === undefined
      && formState.errors.seeding === undefined
      && formState.errors.pricing === undefined) {
      setStep(s => s + 1);
    }
  };
  const handlePrevious = () => {
    setStep(s => s > 0 ? s - 1 : 0);
  };

  const handleCloseBackdrop = () => {
    setLoading(false);
  };

  const handleHome = () => {
    navigation('/');
  };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%'
      }}>
        {step === 0 && (
          <OnboardingPersonalInfo
            control={control}
            register={register}
            formState={formState}
            getFieldState={getFieldState}
            required={step >= 0}
          />
        )}
        {step === 1 && (
          <OnboardingCropInfo
            control={control}
            required={step >= 1}
            cropTypes={Object.values(CropType)}
          />
        )}
        {step === 2 && (
          <OnboardingPriceInfo
            control={control}
            formState={formState}
            watch={watch}
            setValue={setValue}
            blocks={blocks}
            required={step >= 2}
          />
        )}
        {step === 3 && (
          <OnboardingSeedingInfo
            setValue={setValue}
            watch={watch}
            required={step >= 3}
          />
        )}
        {step === lastStep && (
          <OnboardingConfirmation />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: step < lastStep ? "space-between" : "center",
            width: "100%",
            mt: 'auto'
          }}
        >
          {step <= lastStep - 1 && (
            <IconButton color="primary" onClick={handlePrevious} disabled={step === 0}>
              <ChevronLeftIcon />
            </IconButton>
          )}
          {step < lastStep - 1 && (
            <Button type="button" variant="contained" onClick={handleSubmit(handleNext)}>
              {t('onboarding.buttons.next')}
            </Button>
          )}
          {step === lastStep - 1 && (
            <Button type="submit" variant="contained">
              {t('onboarding.buttons.submit')}
            </Button>
          )}
          {step <= lastStep - 1 && (
            <IconButton color="error" onClick={handleHome}>
              <CloseIcon />
            </IconButton>
          )}
          {step === lastStep && (
            <Button type="button" variant="contained" onClick={handleHome}>
              {t('onboarding.buttons.home')}
            </Button>
          )}
        </Box>
      </form>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default OnboardingForm;
