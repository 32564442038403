import i18next from 'i18next';
import { useContext } from 'react';
import LanguageContext from '../contexts/LanguageContext';

function useLang() {
  const {
    setLanguage,
    language,
    supportedLanguages
  } = useContext(LanguageContext);

  const onChangeLanguage: (language: string) => void = (language) => {
    setLanguage(language);
    i18next.changeLanguage(language);
  };

  return {
    onChangeLanguage,
    language,
    supportedLanguages
  };
}

export default useLang;