import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Control, Controller, FormState, UseFormGetFieldState, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control<any, any>,
  required?: boolean,
  cropTypes: string[]
};

function OnboardingCropInfo({ control, required = false, cropTypes }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t('onboarding.titles.crop')}</Typography>
      <Controller
        name={'crop.type'}
        control={control}
        rules={{ required: required }}
        defaultValue='PADDY'
        render={({ field, fieldState: { error } }) =>
          <ToggleButtonGroup
            exclusive
            {...field}
            sx={{ mt: 2, mb: 2 }}
          >
            {cropTypes.map(type =>
              <ToggleButton key={type} value={type}>
                {t(`onboarding.cropTypes.${type}`)}
              </ToggleButton>)
            }
          </ToggleButtonGroup>
        }
      />
      <Controller
        name={'crop.isIrrigated'}
        control={control}
        rules={{ required: required }}
        defaultValue="false"
        render={({ field, fieldState: { error } }) =>
          <ToggleButtonGroup
            exclusive
            {...field}
            sx={{ mt: 2, mb: 2 }}
          >
            <ToggleButton value="false">{t('onboarding.isIrrigated.false')}</ToggleButton>
            <ToggleButton value="true">{t('onboarding.isIrrigated.true')}</ToggleButton>
          </ToggleButtonGroup>
        }
      />
    </>
  );
}

export default OnboardingCropInfo;
