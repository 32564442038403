import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Member from "../../models/Member";

interface Props {
  member: Member;
  handleReturn: () => void;
}

function MemberDetails({ member, handleReturn }: Props) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Personal infos
          </Typography>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              {t("onboarding.fields.name")} :
            </Typography>
            <Typography>{member?.personal.name}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              {t("onboarding.fields.address")} :
            </Typography>
            <Typography>{member?.personal.address}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              {t("onboarding.fields.phone")} :
            </Typography>
            <Typography>{member?.personal.phone}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              gender :
            </Typography>
            <Typography>{member?.personal.gender}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              {t("onboarding.fields.bankName")} :
            </Typography>
            <Typography>{member?.personal.bankName}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              {t("onboarding.fields.bankBranchName")} :
            </Typography>
            <Typography>{member?.personal.bankBranchName}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              {t("onboarding.fields.bankIFSC")} :
            </Typography>
            <Typography>{member?.personal.bankIFSC}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              {t("onboarding.fields.bankAccountNumber")} :
            </Typography>
            <Typography>{member?.personal.bankAccountNumber}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Crop infos
          </Typography>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              crop type :
            </Typography>
            <Typography>{member?.crop.type}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              is irrigated :
            </Typography>
            <Typography>{member?.crop.isIrrigated}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Cover infos
          </Typography>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              Cover start :
            </Typography>
            <Typography>{member?.seeding.periodStart}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              Cover end :
            </Typography>
            <Typography>{member?.seeding.periodEnd}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Cover infos
          </Typography>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              Block :
            </Typography>
            <Typography>{member?.pricing.block}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              Price :
            </Typography>
            <Typography>{member?.pricing.price}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              Payment status :
            </Typography>
            <Typography>{member?.payment.status}</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button variant="outlined" onClick={handleReturn}>
          Return
        </Button>
      </Box>
    </Box>
  );
}

export default MemberDetails;
