import { Box, Button, MenuItem, Select } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useLang from "../hooks/lang";

type Inputs = {
  lang: string;
};

interface Props {
  darkMode?: string;
}

function LanguageSelector({ darkMode }: Props) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { control, watch } = useForm<Inputs>();
  const [open, setOpen] = useState(false);
  const { language, onChangeLanguage, supportedLanguages } = useLang();

  useEffect(() => {
    const subscription = watch((value, {name}) => {
      if (name === "lang" && value.lang !== undefined) {
        onChangeLanguage(value.lang);
      }
    });
    return () => subscription.unsubscribe();
  }, [onChangeLanguage, watch]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box>
      <form>
        <Button
          ref={anchorRef}
          onClick={handleOpen}
          disabled={supportedLanguages.length === 1}
          sx={darkMode ? { color: "background.default" } : {}}
        >
          {language}
        </Button>
        <Controller
          name="lang"
          control={control}
          defaultValue={language || "fr"}
          render={({ field }) => (
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              sx={{ display: "none" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                anchorEl: anchorRef.current,
              }}
              {...field}
            >
              {supportedLanguages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </form>
    </Box>
  );
}

export default LanguageSelector;
