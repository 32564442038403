import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function OnboardingConfirmation() {
  const { t } = useTranslation();

  return (
    <>
      <Typography sx={{ pb: 2, textAlign: 'center' }}>{t('onboarding.texts.confirmation')}</Typography>
    </>
  );
}

export default OnboardingConfirmation;