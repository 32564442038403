import { Alert, Snackbar } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import Layout from "./components/Layout";
import useAuth from "./hooks/auth";
import useError from "./hooks/error";
import Login from "./pages/authentication/Login";
import Home from "./pages/Home";
import MemberDetails from "./pages/listing/Member.details";
import MembersList from "./pages/listing/Members.list";
import OnboardingForm from "./pages/onboarding/Onboarding.form";

const Child = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const { error, setError } = useError();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setOpen(true);
    }
  }, [error]);

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setError(null);
  };

  return (
    <>
      {!isAuthenticated && (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      )}
      {isAuthenticated && (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/onboarding" element={<OnboardingForm />} />
            <Route path="/members/:paymentStatus" element={<MembersList />} />
          </Route>
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
        <Alert severity="error" sx={{ width: '100%' }}>{error !== null && t(`errors.${error}`)}</Alert>
      </Snackbar>
    </>
  );
};

const Router = () => {
  const {
    isAuthenticated
  } = useAuth();

  return (
    <BrowserRouter>
      <Child {...{ isAuthenticated }} />
    </BrowserRouter>
  );
}

export default Router;