import { useState, createContext, ReactElement } from 'react';

interface Context {
  setLanguage: (language: string | null) => void;
  language: string | null;
  supportedLanguages: string[];
}

const LanguageContext = createContext({} as Context);

interface LanguageContextProviderProps {
  initialLanguage: string | null;
  supportedLanguages: string[];
  children: ReactElement | ReactElement[];
}

function LanguageContextProvider({ initialLanguage, supportedLanguages, children }: LanguageContextProviderProps) {
  const [language, setLanguage] = useState<string | null>(initialLanguage);
  
  const context: Context = {
    setLanguage,
    language,
    supportedLanguages
  };

  return (
    <LanguageContext.Provider value={context}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
export { LanguageContextProvider };
