import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/auth";
import PaymentStatus from "../models/PaymentStatus.enum";

function Home() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleClickOnboarding = () => {
    navigate('onboarding');
  };
  const handleClickMembers = () => {
    navigate(`members/${PaymentStatus.payed}`);
  }
  const handleClickPendingMembers = () => {
    navigate(`members/${PaymentStatus.pending}`);
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Typography textAlign={'center'}>
        {t('home.main', { email: user })}
      </Typography>
      <Button
        onClick={handleClickOnboarding}
        variant="contained"
        sx={{
          mt: 2
        }}
      >
        {t('home.onboard')}
      </Button>
      <Button
        onClick={handleClickMembers}
        variant="contained"
        sx={{
          mt: 2
        }}
      >
        {t('home.members')}
      </Button>
      <Button
        onClick={handleClickPendingMembers}
        variant="contained"
        sx={{
          mt: 2
        }}
      >
        {t('home.pendingMembers')}
      </Button>
    </Box>
  );
}

export default Home;
