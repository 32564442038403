import { Theme, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import coverageConfig from "../../../configs/coverage.config.json";
import Member from "../../../models/Member";

interface Props {
  setValue: UseFormSetValue<Member>,
  watch: UseFormWatch<Member>,
  required?: boolean
};

function OnboardingSeedingInfo({ setValue, watch, required = false }: Props) {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [period, setPeriod] = useState(0);

  useEffect(() => {
    setValue('seeding.periodStart', moment(coverageConfig.availableDates[period].start).format("YYYY-MM-DD"));
    setValue('seeding.periodEnd', moment(coverageConfig.endDate).format("YYYY-MM-DD"));
    setValue('payment.cutoffDate', moment(coverageConfig.availableDates[period].cutoff).format("YYYY-MM-DD"))
  }, [period, setValue]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newPeriod: number | null
  ) => {
    if (newPeriod !== null) {
      setPeriod(newPeriod);
    }
  };

  return (
    <>
      <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t('onboarding.titles.seeding')}</Typography>
      <ToggleButtonGroup
        exclusive
        value={period}
        onChange={handleChange}
        sx={{ mt: 2, mb: 2 }}
        orientation={isSmallScreen ? 'vertical' : 'horizontal'}
      >
        {coverageConfig.availableDates.map((p, index) => (
          <ToggleButton key={index} value={index}>
            {`${moment(p.start).format('DD MMM YY')}`}
            <br/>
            {`payment cut-off ${moment(p.cutoff).format('DD MMM YY')}`}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
        {t('onboarding.texts.coverageEnd', {endDate: moment(coverageConfig.endDate).format("DD MMM YY")})}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
        {t('onboarding.texts.insurance', {price: watch('pricing.insurance')})}
      </Typography>
    </>
  );
}

export default OnboardingSeedingInfo;
