import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#f28222',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#069eda'
    },
    background: {
      default: '#F6F6F8',
      paper: '#FFFFFF'
    }
  }
});

export default theme;
