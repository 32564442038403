import { TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Control, Controller, FormState, UseFormGetFieldState, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "../../../components/Phone.input";
import theme from "../../../theme";

interface Props {
  register: UseFormRegister<any>,
  control: Control<any, any>,
  formState: FormState<any>,
  getFieldState: UseFormGetFieldState<any>,
  required?: boolean
};

function OnboardingPersonalInfo({ register, control, formState: { errors }, getFieldState, required = false }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t('onboarding.titles.personal')}</Typography>
      <TextField fullWidth
        variant="outlined"
        label={t('onboarding.fields.name')}
        {...register("personal.name", {
          required: {
            value: required,
            message: t('errors.fields.required')
          }
        })}
        sx={{ m: 2 }}
        error={errors?.personal?.name !== undefined}
        helperText={errors?.personal?.name?.message}
      />
      <TextField fullWidth
        variant="outlined"
        label={t('onboarding.fields.address')}
        {...register("personal.address", {
          required: {
            value: required,
            message: t('errors.fields.required')
          }
        })}
        sx={{ mt: 2, mb: 2 }}
        error={errors?.personal?.address !== undefined}
        helperText={errors?.personal?.address?.message}
      />
      <PhoneInput
        name="personal.phone"
        control={control}
        error={getFieldState('personal.phone').error}
        label={t('onboarding.fields.phone')}
        required={required}
        sx={{ mt: 2, mb: 2, width: '100%' }}
      />
      <TextField fullWidth
        variant="outlined"
        label={t('onboarding.fields.bankName')}
        {...register("personal.bankName", {
          required: {
            value: required,
            message: t('errors.fields.required')
          }
        })}
        sx={{ mt: 2, mb: 2 }}
        error={errors?.personal?.bankName !== undefined}
        helperText={errors?.personal?.bankName?.message}
      />
      <TextField fullWidth
        variant="outlined"
        label={t('onboarding.fields.bankBranchName')}
        {...register("personal.bankBranchName", {
          required: {
            value: required,
            message: t('errors.fields.required')
          }
        })}
        sx={{ mt: 2, mb: 2 }}
        error={errors?.personal?.bankBranchName !== undefined}
        helperText={errors?.personal?.bankBranchName?.message}
      />
      <TextField fullWidth
        variant="outlined"
        label={t('onboarding.fields.bankIFSC')}
        placeholder="SBIN01256A0"
        {...register("personal.bankIFSC", {

          required: {
            value: required,
            message: t('errors.fields.required')
          }
          ,
          pattern: {
            value: /^[A-Z]{4}0[A-Z0-9]{6}$/,
            message: t('errors.fields.format')
          }
        })}
        sx={{ mt: 2, mb: 2 }}
        error={errors?.personal?.bankIFSC !== undefined}
        helperText={errors?.personal?.bankIFSC?.message}
      />
      <TextField fullWidth
        variant="outlined"
        label={t('onboarding.fields.bankAccountNumber')}
        placeholder="111111111"
        {...register("personal.bankAccountNumber", {
          required: {
            value: required,
            message: t('errors.fields.required')
          },
          pattern: {
            value: /^\d{9,18}$/,
            message: t('errors.fields.format')
          }
        })}
        inputProps={{
          inputMode: "numeric"
        }}
        sx={{ mt: 2, mb: 2 }}
        error={errors?.personal?.bankAccountNumber !== undefined}
        helperText={errors?.personal?.bankAccountNumber?.message}
      />
      <Controller
        name={'personal.gender'}
        control={control}
        rules={{ required: required }}
        render={({ field, fieldState: { error } }) =>
          <ToggleButtonGroup
            exclusive
            {...field}
            sx={errors?.personal?.gender !== undefined ?
              { mt: 2, mb: 2, borderWidth: '1px', borderStyle: 'solid', borderColor: theme.palette.error.main }
              : { mt: 2, mb: 2 }
            }
          >
            <ToggleButton value="F">{t('onboarding.genders.F')}</ToggleButton>
            <ToggleButton value="M">{t('onboarding.genders.M')}</ToggleButton>
          </ToggleButtonGroup>
        }
      />
    </>
  );
}

export default OnboardingPersonalInfo;
