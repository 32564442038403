import { Box, Button, TextField, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import AuthService from "../../services/Authenticate";
import useAuth from "../../hooks/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../components/Language.selector";
import useError from "../../hooks/error";
import { useSearchParams } from "react-router-dom";

type Inputs = {
  email: string,
  otp: string
};

function Login() {
  const [searchParams] = useSearchParams();
  const { setError } = useError();
  const [step, setStep] = useState(0);
  const { onLogin } = useAuth();
  const { t } = useTranslation();

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();

  useEffect(() => {
    const email = searchParams.get('email');
    const otp = searchParams.get('otp');
    if (email !== null && otp !== null) {
      AuthService.login(email, otp).then(res => {
        onLogin(res.email, res.accessToken);
      }).catch(error => setError(error.statusCode));
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = data => {
    switch (step) {
      case 1:
        AuthService.login(data.email, data.otp).then(res => {
          onLogin(res.email, res.accessToken);
        }).catch(error => setError(error.statusCode));
        break;
      default: //0
        AuthService.requestOtp(data.email).then(res => {
          if (res.ok) {
            setStep(1);
          }
        }).catch(error => setError(error.statusCode));
        break;
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      p: 4,
      boxSizing: 'border-box'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        boxSizing: 'border-box'
      }}>
        <img
          alt="Logo"
          src='/logo.png'
          style={{ maxWidth: '90%', maxHeight: '16rem' }}
        />
        <form onSubmit={handleSubmit(onSubmit)} style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Typography
            variant="subtitle2"
            color="gray"
            sx={{
              display: step > 0 ? 'block' : 'none',
              mt: 2,
              textAlign: 'center'
            }}
          >
            {t('login.otp_helper')}
          </Typography>
          <TextField
            variant="outlined"
            label={t('login.fields.email')}
            {...register("email", {
              required: {
                value: true,
                message: t('errors.fields.required')
              }
            })}
            sx={{
              display: step === 0 ? 'block' : 'none',
              m: 2
            }}
            error={errors.email !== undefined}
            helperText={errors.email?.message}
          />
          <TextField
            variant="outlined"
            label={t('login.fields.otp')}
            {...register("otp", {
              required: {
                value: step > 0,
                message: t('errors.fields.required')
              }
            })}
            sx={{
              display: step > 0 ? 'block' : 'none',
              m: 2
            }}
            error={errors.otp !== undefined}
            helperText={errors.otp?.message}
          />
          <Button variant="contained" type="submit">{t('login.submit')}</Button>
        </form>
        <LanguageSelector />
      </Box>
      <Typography variant="subtitle2" color="gray">Powered by IBISA</Typography>
    </Box>
  );
}

export default Login;
