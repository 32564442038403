import { useContext } from 'react';
import AuthContext from '../contexts/AuthContext';
import ApiService from '../services/Api';

function useAuth() {
  const {
    isAuthenticated,
    setUser,
    user
  } = useContext(AuthContext);

  const onLogin: (email: string, accessToken: string) => void = (email, accessToken) => {
    ApiService.setTokens(accessToken);
    sessionStorage.setItem('user', email);
    setUser(email);
  };

  const onLogout: () => void = () => {
    ApiService.removeTokens();
    sessionStorage.removeItem('user');
    setUser(null);
  };

  return {
    isAuthenticated,
    onLogin,
    onLogout,
    user
  };
}

export default useAuth;