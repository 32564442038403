import { useEffect } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Control, Controller, FormState, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Member from "../../../models/Member";

interface Props {
  control: Control<Member>,
  formState: FormState<Member>,
  watch: UseFormWatch<Member>,
  setValue: UseFormSetValue<Member>,
  blocks: { name: string, price: number, insurance: number }[],
  required?: boolean
};

function OnboardingPriceInfo({ control, formState: { errors }, watch, setValue, blocks, required = false }: Props) {
  const { t } = useTranslation();

  useEffect(() => {
    setValue('pricing.price', blocks[0].price);
    setValue('pricing.insurance', blocks[0].insurance);
  }, []);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === "pricing.block" && type === 'change' && value.pricing !== undefined && value.pricing.block !== undefined) {
        const block = blocks.find(b => b.name === value.pricing?.block);
        if (block !== undefined) {
          setValue('pricing.price', block.price);
          setValue('pricing.insurance', block.insurance);
        }
      }
    });
    return () => subscription.unsubscribe(); 
  }, [blocks, setValue, watch]);

  return (
    <>
      <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t('onboarding.titles.block')}</Typography>
      <FormControl sx={{ mt: 2, mb: 2, width: '100%' }}>
        <InputLabel id="district-lable">{t('onboarding.fields.block')}</InputLabel>
        <Controller
          name="pricing.block"
          rules={{
            required: {
              value: required,
              message: t('errors.fields.required')
            }
          }}
          defaultValue={blocks[0].name}
          control={control}
          render={({ field, formState: { errors } }) =>
            <Select
              variant="outlined"
              labelId="district-label"
              label={t("onboarding.fields.block")}
              {...field}
              error={errors?.pricing?.block !== undefined}
            >
              {blocks.map(block => (
                <MenuItem key={block.name} value={block.name}>{block.name}</MenuItem>
              ))}
            </Select>
          }
        />
        {errors?.pricing?.block?.message && (
          <FormHelperText sx={(theme) => ({ color: theme.palette.error.main })}>{errors?.personal?.name?.message}</FormHelperText>
        )}
      </FormControl>
      <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t('onboarding.titles.price')}</Typography>
      <Typography  sx={{ p: 2, textAlign: 'center' }}>{t('onboarding.texts.price', {price: watch('pricing.price')})}</Typography>
    </>
  );
}

export default OnboardingPriceInfo;