import {
  Box,
  Button,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { areEqual, FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { memo, useEffect, useState } from "react";
import memoize from "memoize-one";
import OnboardingService from "../../services/Onboarding";
import Member from "../../models/Member";
import MemberDetails from "./Member.details";
import PaymentStatus from "../../models/PaymentStatus.enum";
import { useParams } from "react-router-dom";
import MemberListFilter from "./Member.list.filter";
import blocks from "../../configs/block.config.json";
import { useForm } from "react-hook-form";
import MemberPaymentConfirmation from "./Member.payment.confirmation";

const Row = memo((props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const handleClick = () => {
    data.handleClick(index);
  };

  return (
    <ListItem style={style} key={index} divider sx={{ p: 0 }}>
      <ListItemButton onClick={handleClick} sx={{ p: 0 }}>
        {data.paymentMode && (
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={data.selectedItems[index]}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
        )}
        <ListItemText
          primary={`${data.items[index].personal.name}`}
          secondary={`${data.items[index].personal.bankAccountNumber}\n${data.items[index].pricing.block}`}
          sx={{ whiteSpace: "pre-line" }}
        />
      </ListItemButton>
    </ListItem>
  );
}, areEqual);

const createItemData = memoize(
  (items, handleClick, selectedItems, paymentMode) => ({
    items,
    handleClick,
    selectedItems,
    paymentMode,
  })
);

function MembersList() {
  const { paymentStatus = "PAID" } = useParams();
  const [items, setItems] = useState<Member[]>([]);
  const [paymentMode, setPaymentMode] = useState(false);
  const [confirmationMode, setConfirmationMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState<boolean[]>([]);
  const [selectedMember, setSelectedMember] = useState<Member>();
  const { control, watch } = useForm<{ selectedBlock: string }>();

  useEffect(() => {
    OnboardingService.getAll(paymentStatus, watch("selectedBlock")).then(
      (res) => {
        setItems(res);
        setSelectedItems(Array(res.length).fill(false));
        setSelectedMember((selectedMember) =>
          res.find((member) => member?.id === selectedMember?.id)
        );
      }
    );
  }, [paymentStatus, watch("selectedBlock")]);
  useEffect(() => {
    setSelectedItems((curr) => Array(curr.length).fill(false));
  }, [paymentMode]);

  const handleClick = (index: number) => {
    if (paymentMode) {
      setSelectedItems((selectedItems) => {
        const tmp = [...selectedItems];
        tmp[index] = !tmp[index];
        return tmp;
      });
    } else {
      setSelectedMember(items[index]);
    }
  };
  const handleSelectAll = () => {
    setSelectedItems((curr) => Array(curr.length).fill(true));
  };
  const handleReturn = () => {
    setSelectedMember(undefined);
  };
  const handleHasPayed = () => {
    setConfirmationMode(true);
  };
  const handlePaymentConfirmation = () => {
    const ids = items
      .filter((item, index) => selectedItems[index])
      .map((item) => item.id);
    OnboardingService.patchManyPaymentStatus(ids, PaymentStatus.payed).then(
      () => {
        OnboardingService.getAll(paymentStatus, watch("selectedBlock")).then(
          (res) => {
            setItems(res);
            setPaymentMode(false);
            setConfirmationMode(false);
          }
        );
      }
    );
  };
  const handleCancelConfirmation = () => {
    setConfirmationMode(false);
  };

  const itemData = createItemData(
    items,
    handleClick,
    selectedItems,
    paymentMode
  );

  return (
    <Box
      sx={theme => ({
        display: "flex",
        flexDirection: "column",
        height: `calc(100vh - ${theme.spacing(13)})`,
      })}
    >
      {selectedMember !== undefined && (
        <MemberDetails member={selectedMember} handleReturn={handleReturn} />
      )}
      {selectedMember === undefined && !confirmationMode && (
        <>
          <MemberListFilter
            blocks={blocks.map((b) => b.name)}
            control={control}
          />
          {items.length > 0 && (
            <>
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  mb: 2,
                }}
              >
                <AutoSizer>
                  {({ height, width }) => (
                    <FixedSizeList
                      height={height}
                      width={width}
                      itemData={itemData}
                      itemSize={80}
                      itemCount={items.length}
                      overscanCount={5}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Box>
              {paymentMode && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignSelf: "center",
                  }}
                >
                  <Button onClick={handleSelectAll}>Select All</Button>
                  <Button
                    color="error"
                    disabled={!selectedItems.find((item) => item)}
                    onClick={handleHasPayed}
                  >
                    Has Paid
                  </Button>
                </Box>
              )}
              {paymentStatus === PaymentStatus.pending && (
                <Button onClick={() => setPaymentMode((curr) => !curr)}>
                  Payment Mode
                </Button>
              )}
            </>
          )}
          {items.length === 0 && <Typography>No results...</Typography>}
        </>
      )}
      {selectedMember === undefined && confirmationMode && (
        <MemberPaymentConfirmation
          cancelCallback={handleCancelConfirmation}
          confirmationCallback={handlePaymentConfirmation}
          numberOfMembers={selectedItems.filter((item) => item).length}
        />
      )}
    </Box>
  );
}

export default MembersList;
