import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { t } from "i18next";
import { Control, Controller } from "react-hook-form";

interface Props {
  control: Control<{ selectedBlock: string }>;
  blocks: string[];
}

function MemberListFilter({blocks, control}: Props) {
  return (
    <form>
      <FormControl sx={{ mt: 2, mb: 2, width: "100%" }}>
        <InputLabel id="district-lable">
          {t("onboarding.fields.block")}
        </InputLabel>
        <Controller
          name="selectedBlock"
          defaultValue={'ALL'}
          control={control}
          render={({ field }) => (
            <Select
              variant="outlined"
              labelId="district-label"
              label={t("onboarding.fields.block")}
              {...field}
            >
              <MenuItem value={'ALL'}>{t("onboarding.fields.select.all")}</MenuItem>
              {blocks.map((block) => (
                <MenuItem key={block} value={block}>
                  {block}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </form>
  );
}

export default MemberListFilter;
