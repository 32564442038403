import { Box, Button, Typography } from "@mui/material";
import { t } from "i18next";

interface Props {
  confirmationCallback: () => any;
  cancelCallback: () => any;
  numberOfMembers: number;
}

function MemberPaymentConfirmation({
  confirmationCallback,
  cancelCallback,
  numberOfMembers,
}: Props) {
  return (
    <Box>
      <Typography textAlign={'center'}>
        {t("members.confirmation", { count: numberOfMembers })}
      </Typography>
      <Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
        <Button onClick={confirmationCallback}>{t("members.buttons.confirm")}</Button>
        <Button color="error" onClick={cancelCallback}>{t("members.buttons.cancel")}</Button>
      </Box>
    </Box>
  );
}

export default MemberPaymentConfirmation;
