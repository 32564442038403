import ApiService from './Api';
import Member from "../models/Member";
import PaymentStatus from '../models/PaymentStatus.enum';

class Onboarding {

  save(member: Member): Promise<void> {
    return ApiService.post('/members', member);
  }

  getAll(paymentStatus: string, block?: string): Promise<Member[]> {
    return ApiService.get(`/members/${paymentStatus}${block && block !== 'ALL' ? `?block=${block}` : ''}`);
  }

  patchPaymentStatus(id: number, paymentStatus: PaymentStatus) {
    return ApiService.patch(`/members/${id}`, { paymentStatus })
  }

  patchManyPaymentStatus(ids: (number | undefined)[], paymentStatus: PaymentStatus) {
    return ApiService.patch(`/members`, { ids: ids.filter(id => id !== undefined), patch: { paymentStatus } })
  }

}

export default new Onboarding();
